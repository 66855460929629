import * as Sentry from '@sentry/vue';

import { type DeploymentBackup, DeploymentState } from '@clovyr/pollen';
import { BackupAPI } from '@clovyr/pollen/backup';
import { isClovyrCode } from '@clovyr/pollen/garden';
import { collectImages, DeploymentMethod, type Manifest } from '@clovyr/pollen/manifest';
import { getBuilderForManifest } from '@clovyr/pollen/manifest/configure';
import { writeAllFilesBase64 } from '@clovyr/pollen/manifest/configure/util';
import { execWithOutput, upgradeInstanceConfigure } from '@clovyr/pollen/remote_exec';
import {
  pullAllImages,
  restartApp,
  stopApp,
  upgradeClovyrServices,
} from '@clovyr/pollen/remote_exec/docker';
import { upgradeClovyrServicesK8S } from '@clovyr/pollen/remote_exec/kubernetes';

import { config } from '@/init/config';
import { usePollenStore } from '@/stores/pollen_store';
import { UpdateStatus, useUpdateStore } from '@/stores/update_store';

export const useAppVersion = (deploymentID: Ref<string> | string) => {
  const pollenStore = usePollenStore();

  const deployment = computed(() => {
    const id = typeof deploymentID === 'string' ? deploymentID : deploymentID.value;
    return pollenStore.getDeploymentByID(id)!;
  });

  const app = computed(() => {
    return pollenStore.getDeploymentApp(deployment.value)!;
  });

  /**
   * Checks the currently running version of the deployment against the latest available version.
   */
  const isUpdateAvailable = computed(() => {
    if (deployment.value?.appID && deployment.value.appID.includes('fedimint')) {
      // TODO: remove this once fedi gives us the green light for upgrades
      return false;
    }
    return (
      deployment.value?.appVersion &&
      app.value?.metadata?.version &&
      !isClovyrCode(app.value.metadata.id) &&
      deployment.value.appVersion !== app.value.metadata.version
    );
  });

  /**
   * Create a named backup of the current deployment.
   *
   * @param backupName
   */
  async function takeBackup(backupName: string) {
    const snapshotID = await new BackupAPI(
      deployment.value.fqdn!,
      deployment.value.accessList[0],
      deployment.value.appID,
    ).createSnapshot(['keep']);

    deployment.value.backups ||= [];
    deployment.value.backups.push({
      snapshotID,
      createdAt: Date.now(),
      name: backupName,
      appVersion: deployment.value.appVersion!,
    });
  }

  /**
   * Updates the deployment to run the given version of the app.
   *
   * Process:
   *
   *   1. Update app image settings from new manifest
   *   2. Write new config on host
   *   3. Pull new images
   *   4. Stop the app
   *   5. Take a snapshot
   *   6. Restore snapshot, if given
   *   7. Restart the app
   *
   * @param app
   * @param backup if given, restore this backup before starting the app
   * @param emitter
   */
  // eslint-disable-next-line @typescript-eslint/no-shadow
  async function runAppVersion(app: Manifest, backup?: DeploymentBackup) {
    deployment.value.state = DeploymentState.Updating;
    await pollenStore.pollen.putDeployment(deployment.value);

    const { addJob } = useUpdateStore();
    const job = addJob({
      manifest: app,
      deployment: deployment.value,
      status: UpdateStatus.Started,
    });

    try {
      // update app image settings from new manifest
      const images = collectImages(app);
      images.forEach((image) => {
        deployment.value.appSettings[image.id] = image.image;
      });
      await pollenStore.pollen.putDeployment(deployment.value);

      // write new config on host
      const builder = getBuilderForManifest(app, DeploymentMethod.DockerCompose);
      const [, , files] = builder.prepareConfigureData(app, deployment.value.appSettings);

      const writeConfigScript = `
pushd /opt/clovyr/apps/${app.metadata.id}
${writeAllFilesBase64(files)}
  `;

      const host = deployment.value.fqdn!;
      const key = deployment.value.accessList[0];
      await execWithOutput(host, key, writeConfigScript);

      // now pull & restart
      job.status = UpdateStatus.Pulling;
      await pullAllImages(host, key, deployment.value.appID);

      job.status = UpdateStatus.Stopping;
      await stopApp(host, key, deployment.value.appID);

      job.status = UpdateStatus.Snapshotting;
      const backupName = `Version ${deployment.value.appVersion} (before ${backup ? 'rollback' : 'update'} to ${app.metadata.version})`;
      await takeBackup(backupName);

      if (backup) {
        job.status = UpdateStatus.Restoring;
        await new BackupAPI(host, key, deployment.value.appID).restoreSnapshot(
          backup.snapshotID,
          true,
        );
      }

      job.status = UpdateStatus.Restarting;
      await restartApp(host, key, deployment.value.appID);

      // update version & save
      deployment.value.appVersion = app.metadata.version;
      deployment.value.state = DeploymentState.Running;
      await pollenStore.pollen.putDeployment(deployment.value);
    } catch (e) {
      Sentry.captureException(e);
      console.error(e);
      // just revert to running for now, user can likely just try again.
      deployment.value.state = DeploymentState.Running;
      await pollenStore.pollen.putDeployment(deployment.value);
    }

    job.status = UpdateStatus.Complete;
  }

  async function runPostLaunchTasks() {
    void upgradeInstanceConfigure(
      deployment.value!.fqdn!,
      deployment.value!.accessList[0],
      config.VITE_APP_ENV,
    ).then(() => {
      if (isClovyrCode(app.value.metadata.id)) {
        void upgradeClovyrServicesK8S(deployment.value!.fqdn!, deployment.value!.accessList[0]);
      } else {
        void upgradeClovyrServices(
          deployment.value!.fqdn!,
          deployment.value!.accessList[0],
          deployment.value!.appID,
        );
      }
    });
  }

  return {
    isUpdateAvailable,

    runAppVersion,
    runPostLaunchTasks,
  };
};
