<script setup lang="ts">
import { storeToRefs } from 'pinia';

import { Button } from '@clovyr/bed';
import type { Deployment } from '@clovyr/pollen/types';

import LibraryAppCard from '@/components/blocks/LibraryAppCard.vue';
import GardenIcon from '@/components/icons/GardenIcon.vue';
import { useRouteHead } from '@/composables/useRouteHead';
import { usePollenStore } from '@/stores/pollen_store';
import { useUserFlagsStore } from '@/stores/user_flags';
import Unlock from '@/views/Settings/AuthForms/Unlock.vue';
import UnlockSecret from '@/views/Settings/AuthForms/UnlockSecret.vue';

type LibraryProps = {
  shareAppId?: string;
  shareAppName?: string;
  itemID?: string;
  shareSecret?: string;
};

const props = defineProps<LibraryProps>();

const pollenStore = usePollenStore();
const { sortedDeployments, isLocked, isLoggedInWithoutSecret } = storeToRefs(pollenStore);
const { garden, getDeploymentApp } = pollenStore;

const router = useRouter();

const userFlagStore = useUserFlagsStore();
const { canAccessInternalOnlyFeatures } = storeToRefs(userFlagStore);

useRouteHead();

let delCount = 0;
const deleteLabel = ref('Delete All Apps');

const handleFindGarden = async () => {
  return router.push('/');
};

async function onClickDeleteAll() {
  delCount++;
  if (delCount === 1) {
    deleteLabel.value = 'Are you sure?';
  } else if (delCount === 2) {
    // Wait for delete operations to complete so that Playwright tests know when
    // cleanup is complete.
    await Promise.all(
      pollenStore.deployments.map((deployment) => pollenStore.deleteDeployment(deployment)),
    );

    delCount = 0;
    deleteLabel.value = 'Delete All Apps';
  }
}

const sharedAppManifest = computed(() => {
  if (!props.shareAppId) {
    return undefined;
  }
  let app = garden.getAppByID(props.shareAppId);
  if (!app) {
    const m = props.shareAppId.match(/^(.*?)-[0-9]$/);
    if (m) {
      app = garden.getAppByID(m[1]);
    }
  }
  return app;
});

const sharedAppDeployment = computed<Deployment | undefined>(() => {
  if (props.shareAppId && props.shareAppName) {
    return {
      appID: props.shareAppId,
      instanceName: props.shareAppName,
    } as Deployment;
  }
  return undefined;
});
</script>

<template>
  <div class="library">
    <div class="library__background" />

    <!-- nav -->
    <div class="container">
      <div class="grid">
        <div class="grid__col">
          <div class="library__header library__header--library">
            <div class="library__header__title">App Library</div>
            <div class="library__header__divider" />
          </div>
        </div>
      </div>

      <div
        v-if="
          canAccessInternalOnlyFeatures &&
          !isLoggedInWithoutSecret &&
          !isLocked &&
          sortedDeployments.length
        "
        class="grid delete-all"
      >
        <Button :label="deleteLabel" @click="onClickDeleteAll" />
      </div>

      <!-- your apps -->
      <div class="library__apps">
        <!--No secret key message-->
        <UnlockSecret v-if="isLoggedInWithoutSecret && !sharedAppDeployment" />

        <!-- locked (enter password) -->
        <Unlock v-else-if="isLocked && !sharedAppDeployment" />

        <!-- app cards -->
        <template v-else>
          <div v-if="sharedAppDeployment && shareAppId" class="library__apps__app">
            <LibraryAppCard
              :app="sharedAppManifest"
              :deployment="sharedAppDeployment"
              :itemID="itemID"
              :shareSecret="shareSecret"
              is-shared
            />
          </div>
          <div
            v-for="deployment in sortedDeployments"
            :key="deployment.instanceName"
            class="library__apps__app"
          >
            <LibraryAppCard :app="getDeploymentApp(deployment)!" :deployment="deployment" />
          </div>
          <div class="library__apps__app">
            <a class="module-placeholder" @click="handleFindGarden">
              <h2>
                Find something new in the
                <span class="text text--color-action"
                  >Garden<GardenIcon style="margin-top: -15px"
                /></span>
              </h2>
            </a>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.library {
  position: relative;

  &__background {
    @include blurred-background('@clovyr/bed/assets/images/nature-bg.png');
  }

  &__header {
    @include text--mono;

    @include flex($justify: space-between, $align: center);

    min-height: space(10);
    margin-top: space(4);

    @include media-breakpoint-up(md) {
      margin-top: 0;
    }

    @include media-query(phone) {
      flex-direction: column;
      flex-wrap: wrap;
      padding: space(5) 0 space(4);
    }

    &--bookmarked {
      margin-top: space(4);

      .library__header__divider {
        display: block;
      }
    }

    &__title,
    &__actions {
      flex: 1 0 auto;
    }

    &__title {
      color: color(grey, tertiary);

      @include media-query(phone) {
        flex-grow: 0;
        margin-bottom: space(2);
      }
    }

    &__divider {
      position: relative;
      flex: 1 1 100%;

      @include media-query(tablet-sm-and-below) {
        display: none;
      }

      @include media-query(phone) {
        flex: 1 1 auto;
      }

      &::after {
        @include position(absolute, top, calc(50% - 1px), left right, space(3));

        content: '';
        border-top: 1px solid color(grey, secondary);

        @include media-query(phone) {
          right: 0;
          left: space(2);
        }
      }
    }
  }

  &__apps {
    @include flex($wrap: wrap);

    padding-right: var(--col-gutter-outer-right);
    padding-left: calc(var(--col-gutter-outer-left));

    &__app {
      @include responsive(
        flex-basis,
        (
          default: 33.333%,
          tablet-lg: 50%,
          tablet-sm-and-below: 100%,
        )
      );

      flex-grow: 0;
      flex-shrink: 0;

      @include media-query(full) {
        &:nth-child(3n + 1) {
          @include padding(
            right,
            calc(1.333 * var(--module-pad-horiz) + 0.5 * var(--module-margin-bottom))
          );
        }

        &:nth-child(3n + 2) {
          @include padding(
            left right,
            calc(1.333 / 2 * var(--module-pad-horiz) + 0.5 * var(--module-margin-bottom))
          );
        }

        &:nth-child(3n + 3) {
          @include padding(
            left,
            calc(1.333 * var(--module-pad-horiz) + 0.5 * var(--module-margin-bottom))
          );
        }
      }

      @include media-query(tablet-lg) {
        &:nth-child(2n + 1) {
          @include padding(
            right,
            calc(var(--module-pad-horiz) + 0.5 * var(--module-margin-bottom))
          );
        }

        &:nth-child(2n + 2) {
          @include padding(left, calc(var(--module-pad-horiz) + 0.5 * var(--module-margin-bottom)));
        }
      }

      :deep(.module),
      :deep(.module-placeholder) {
        min-height: space(45);

        @include media-query(tablet-lg) {
          min-height: space(40.1);
        }
      }

      :deep(.module) {
        @include media-query(tablet-sm) {
          min-height: space(30);
        }

        @include media-query(phone) {
          min-height: space(28.5);
        }
      }

      :deep(.module-placeholder) {
        @include media-query(tablet-sm-and-below) {
          min-height: 0;
        }

        h2 {
          max-width: 5em;

          @include media-query(tablet-lg) {
            max-width: 10em;
          }

          @include media-query(tablet-sm) {
            max-width: 12em;
          }

          @include media-query(phone) {
            max-width: 5em;
          }
        }

        svg {
          display: inline-block;
          width: 1em;
          height: 1em;
          stroke: color(action);
          transform: translate(0.15em, 0.1em);
        }
      }
    }
  }

  &__bookmarked {
    @include flex($wrap: wrap, $justify: flex-start);
    @include margin(left right, calc(var(--module-pad-horiz) * -1));

    margin-bottom: space(10);

    @include media-query(tablet-sm-and-below) {
      display: block;
    }

    &__app {
      @include flex($wrap: wrap, $align: center);
      @include responsive(
        flex,
        (
          default: 0 0 33.333%,
          tablet: 0 0 50%,
          phone: 0 0 100%,
        )
      );

      padding-right: calc(var(--module-pad-horiz) + #{space(2)});
      padding-left: var(--module-pad-horiz);
      margin-bottom: space(4);

      &__logo {
        flex: 0;
        margin-right: space(1);
      }

      &__name {
        flex: 1;
      }

      &__actions {
        @include flex;

        flex: 1 0 100%;
        margin-top: space(1);

        // .button:not(:hover) .bookmark-icon path {
        //     fill: color(action);
        // }
      }
    }
  }
}

:deep(.form) {
  .form__submit {
    width: fit-content;
  }
  .textinput-block {
    margin-bottom: 0.5rem;
  }
  .textinput-block__input,
  .password-reveal {
    background-color: #251f30;
  }
}
.delete-all {
  padding-bottom: 30px;
}
</style>
