import { DigitalOcean } from '@clovyr/pollen/compute/digitalocean';
import { Linode } from '@clovyr/pollen/compute/linode';
import { wnextHTTP } from '@clovyr/pollen/http';

import { config as launcherConfig } from '@/composables/useInstanceLauncher';

// @ts-expect-error missing props, lazy-loaded at runtime
export const config: AppConfig = {};
let configLoaded = false;

export type AppConfig = {
  VITE_APP_ENV: string;
  // pollen configs for launching apps
  VITE_S3_URL: string;
  VITE_GARDEN_URL: string;
  VITE_INTERNAL_GARDEN_URL: string;
  VITE_PUBLIC_URL: string;
  VITE_ALLOWED_ORIGINS: string;
  // docker registry hostname
  VITE_REGISTRY_HOST: string;
  // compute provider configs
  VITE_OAUTH_LINODE_CLIENT_ID: string;
  VITE_OAUTH_LINODE_CALLBACK_URI: string;
  VITE_DO_SSH_KEY_ID: string;
  VITE_OAUTH_DIGITALOCEAN_CLIENT_ID: string;
  VITE_OAUTH_DIGITALOCEAN_CALLBACK_URI: string;
  // instance-configure filename & sha
  VITE_IC_BIN: string;
  VITE_IC_SHA: string;
  VITE_IC_URL: string;
  // sentry error reporting
  VITE_SENTRY_DSN: string;
  VITE_SENTRY_SAMPLE_RATE: string;
};

/**
 * Grab required vars from env, in development.
 *
 * This should never be *used* in prod.
 *
 * @returns
 */
export function envConfig(): AppConfig {
  const gardenURL: string =
    import.meta.env.VITE_GARDEN_URL || 'https://cstatic.app/local/garden/catalog';
  return {
    VITE_ALLOWED_ORIGINS: import.meta.env.VITE_ALLOWED_ORIGINS,
    VITE_APP_ENV: import.meta.env.VITE_APP_ENV,
    VITE_DO_SSH_KEY_ID: import.meta.env.VITE_DO_SSH_KEY_ID,
    VITE_GARDEN_URL: gardenURL,
    VITE_INTERNAL_GARDEN_URL:
      import.meta.env.VITE_INTERNAL_GARDEN_URL || gardenURL.replace('garden', 'internal'),
    VITE_IC_BIN: import.meta.env.VITE_IC_BIN,
    VITE_IC_SHA: import.meta.env.VITE_IC_SHA,
    VITE_IC_URL: import.meta.env.VITE_IC_URL,
    VITE_OAUTH_DIGITALOCEAN_CALLBACK_URI: import.meta.env.VITE_OAUTH_DIGITALOCEAN_CALLBACK_URI,
    VITE_OAUTH_DIGITALOCEAN_CLIENT_ID: import.meta.env.VITE_OAUTH_DIGITALOCEAN_CLIENT_ID,
    VITE_OAUTH_LINODE_CALLBACK_URI: import.meta.env.VITE_OAUTH_LINODE_CALLBACK_URI,
    VITE_OAUTH_LINODE_CLIENT_ID: import.meta.env.VITE_OAUTH_LINODE_CLIENT_ID,
    VITE_PUBLIC_URL: import.meta.env.VITE_PUBLIC_URL,
    VITE_REGISTRY_HOST: import.meta.env.VITE_REGISTRY_HOST,
    VITE_S3_URL: import.meta.env.VITE_S3_URL,
    VITE_SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
    VITE_SENTRY_SAMPLE_RATE: import.meta.env.VITE_SENTRY_SAMPLE_RATE,
  };
}

/**
 * Small wrapper so we only freeze config in prod env
 * @param obj
 * @returns
 */
function freeze<T>(obj: T): T {
  if (import.meta.env.SSR && import.meta.env.PROD) {
    return Object.freeze(obj);
  }
  return obj;
}

/**
 * Set all per-env config vars in pollen and/or clovyrapp
 */
export function setConfig(cfg: AppConfig, trefoilURL: string) {
  wnextHTTP.setBaseURL(trefoilURL);

  launcherConfig.s3URL = cfg.VITE_S3_URL;
  freeze(launcherConfig);

  Linode.config = freeze({
    ClientID: cfg.VITE_OAUTH_LINODE_CLIENT_ID,
    CallbackURI: cfg.VITE_OAUTH_LINODE_CALLBACK_URI,
  });

  const key = cfg.VITE_DO_SSH_KEY_ID;
  let keys: number[] | undefined;
  if (key) {
    keys = [Number.parseInt(key, 10)];
  }
  DigitalOcean.config = freeze({
    ClientID: cfg.VITE_OAUTH_DIGITALOCEAN_CLIENT_ID,
    CallbackURI: cfg.VITE_OAUTH_DIGITALOCEAN_CALLBACK_URI,
    SSHKeys: keys,
  });

  Object.assign(config, cfg);
  freeze(config);
  configLoaded = true;
}

export async function initConfig(): Promise<AppConfig> {
  if (configLoaded) {
    // mainly in the server-api case, to make sure we don't init more than once
    return config;
  }

  // Primarily in local dev env where we might want to point to staging, we need
  // to pass a custom fully-qualified url. In the general case, pointing to
  // '/api' of the server where the page was served from should suffice.
  const trefoilURL = import.meta.env.VITE_TREFOIL_URL || '/api';
  if (import.meta.env.DEV || import.meta.env.SSR) {
    setConfig(envConfig(), trefoilURL); // set defaults (really only for dev as SSR gets set in initAPI)
    return config;
  }

  if (!import.meta.env.SSR && window?.clovyr?.config) {
    // use config sent with html
    setConfig(window.clovyr.config, trefoilURL);
    return window.clovyr.config;
  }

  // async fetch config and return in browser
  return new Promise((resolve) => {
    void fetch('/assets/config.json')
      .then(async (res) => {
        if (res.status === 200) {
          const data = await res.json();
          if (data && 'config' in data) {
            setConfig(data.config as AppConfig, trefoilURL);
          } else {
            console.error('failed to load config.json');
          }
        }
      })
      .finally(() => {
        resolve(config);
      });
  });
}
