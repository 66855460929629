<script setup lang="ts">
import { storeToRefs } from 'pinia';

import PasswordFormField from '@/components/blocks/PasswordFormField.vue';
import FormWrapper from '@/components/forms/FormWrapper.vue';
import { usePollenStore } from '@/stores/pollen_store';

const emit = defineEmits<{
  (e: 'close'): void;
}>();

export type UnlockProps = { hed?: string; modal?: boolean };
const props = withDefaults(defineProps<UnlockProps>(), {
  hed: 'Your account is currently locked',
  modal: false,
});

const pollenStore = usePollenStore();
const { pollen } = pollenStore;
const { isLocked } = storeToRefs(pollenStore);

const errorMessage = ref('');

const onSubmitUnlock = async (values) => {
  if (!values.password) {
    errorMessage.value = 'password is required';
    return;
  }
  if (values.password) {
    try {
      await pollen.unlock(values.password);
      if (props.modal) {
        emit('close');
      }
    } catch (e) {
      console.warn(e);
      errorMessage.value = 'login failed';
    }
  }
};
</script>

<template>
  <div v-if="isLocked" class="no-key-message module-placeholder" :class="{ modal }">
    <h2>{{ hed }}</h2>
    <p>Re-enter your password to continue</p>
    <div class="form">
      <FormWrapper submit-label="unlock" @submit="onSubmitUnlock">
        <template #fields>
          <PasswordFormField
            name="password"
            label="Password"
            shape="square"
            type="password"
            reveal
          />
          <div class="error-message" v-if="errorMessage">{{ errorMessage }}</div>
        </template>
      </FormWrapper>
    </div>
  </div>
</template>

<style scoped>
.no-key-message {
  border: none;
  background: var(--cardGrad-2, linear-gradient(180deg, rgba(65, 59, 79, 0.5) 0%, #413b4f 91.78%));
  @include media-query(tablet) {
    width: 100%;
  }
  @include media-query(phone) {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }

  h2 {
    margin-bottom: 1rem;
    padding-right: 2rem;
  }

  p {
    padding-right: 5rem;
    margin-bottom: 1rem;
    color: var(--Grey-2, #ada6b7);
    font-size: 16px;
  }

  .form {
    margin-bottom: 1rem;
  }

  .error-message {
    color: color(bad, secondary);
  }
}

.no-key-message.show-unlock {
  max-width: 600px;
  border: 1px solid red;
}
</style>
