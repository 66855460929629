<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { toTypedSchema } from '@vee-validate/zod';
import { z } from 'zod';

import { getAllQueryParams } from '@clovyr/pollen/http/qs';
import { removeWhitespace } from '@clovyr/pollen/util/string';

import PasswordFormField from '@/components/blocks/PasswordFormField.vue';
import FormWrapper from '@/components/forms/FormWrapper.vue';
import { usePollenStore } from '@/stores/pollen_store';

import { validateSecretKey } from './util';

const emit = defineEmits<{
  (e: 'close'): void;
}>();

export type UnlockSecretProps = { hed?: string; subHed?: string; modal?: boolean };
const props = withDefaults(defineProps<UnlockSecretProps>(), {
  hed: 'Your Secret Key is required to display and launch your apps',
  subHed: `You can still access most of Clovyr without it, but you’ll need your secret key to connect to your library of apps.`,
  modal: false,
});

const pollenStore = usePollenStore();
const { pollen } = pollenStore;
const { isLoggedInWithoutSecret } = storeToRefs(pollenStore);

const secretKeySchema = z.object({
  secretKey: validateSecretKey,
});
const schema = toTypedSchema(secretKeySchema);
const query = getAllQueryParams();
const secretKeyParam = query.get('secret') || '';

const onSubmit = async (values) => {
  values.secretKey &&= removeWhitespace(values.secretKey);
  if (values.secretKey) {
    await pollen.addSecretKey(values.secretKey);
    if (props.modal) {
      emit('close');
    }
  }
};
</script>

<template>
  <div v-if="isLoggedInWithoutSecret" class="no-key-message module-placeholder" :class="{ modal }">
    <h2>{{ hed }}</h2>
    <p>
      {{ subHed }}
    </p>
    <div class="form">
      <FormWrapper
        submit-label="access full account"
        :validation-schema="schema"
        @submit="onSubmit"
      >
        <template #fields>
          <PasswordFormField
            name="secretKey"
            label="Secret Key"
            shape="square"
            type="password"
            :model-value="secretKeyParam"
            reveal
          >
            <template #tooltip>
              Your Secret Key can be found in your <a href="/">Recovery Kit</a>. Clovyr does not
              have access to your Secret Key and cannot help recover it if you lose it.
            </template>
          </PasswordFormField>
        </template>
      </FormWrapper>
    </div>
  </div>
</template>

<style scoped lang="scss">
.no-key-message {
  border: none;
  background: var(--cardGrad-2, linear-gradient(180deg, rgba(65, 59, 79, 0.5) 0%, #413b4f 91.78%));
  width: 60%;
  @include media-query(tablet) {
    width: 100%;
  }
  @include media-query(phone) {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }

  h2 {
    margin-bottom: 1rem;
    padding-right: 2rem;
  }

  p {
    padding-right: 5rem;
    margin-bottom: 1rem;
    color: var(--Grey-2, #ada6b7);
    font-size: 16px;
  }

  .form {
    margin-bottom: 1rem;
  }

  .error-message {
    color: color(bad, secondary);
  }
}

.no-key-message.show-secret {
  max-width: 600px;
  background: unset;
}
</style>
