import { type ApiResponse, type FetchWrapper, wnextHTTP } from '../http';

import { APIError } from './APIError';
import { APIResponse, type JSendError, type JSendFail } from './APIResponse';

/**
 * Unwrap API Response
 *
 * @param res
 * @returns
 */
export async function unwrapRes(
  res: Response | Error | Promise<Response | Error>
): Promise<APIResponse> {
  // test if res is a promise
  if (res instanceof Promise) {
    res = await res;
  }
  if (res instanceof Response) {
    if (res.status === 204) {
      return Promise.resolve({ status: 'success' });
    }
    const r = new APIResponse(await res.json());
    if (r.status !== 'success') {
      let { url } = res;
      const m = url.match(/^(https:\/\/.*?)(\/.*)$/);
      if (m) {
        url = m[2];
      }
      if (r.status) {
        r.message = `${r.message} (${url})`;
        throw new APIError(r as JSendError | JSendFail);
      } else {
        throw new APIError(`${res.statusText} (${url})`, res.status);
      }
    }
    return r;
  }
  // error making request
  throw res as Error;
}

export class WNextBase {
  client: FetchWrapper;

  /**
   *
   * @param baseUrl String Base API endpoint (default: /api)
   */
  constructor(baseUrl?: string, client?: FetchWrapper) {
    this.client = client || wnextHTTP;
  }

  url(path: string): string {
    return (path[0] === '/' ? '' : '/') + path;
  }

  /**
   * Unwrap API Response
   *
   * @param res
   * @returns
   * @deprecated Use unwrapRes instead (for newer APIs)
   */
  async unwrap<T>(res: ApiResponse | Error): Promise<T | any> {
    if (res instanceof Response) {
      if (res.status !== 204) {
        return res.json() as Promise<T>;
      }
      return Promise.resolve();
    }
    throw res as Error;
  }

  /**
   * Unwrap API Response
   *
   * @param res
   * @returns
   */
  async unwrapRes(res: Response | Error | Promise<Response | Error>): Promise<APIResponse> {
    return unwrapRes(res);
  }
}
