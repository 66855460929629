<script setup lang="ts">
/**
 *
 * Common modals used throughout the application and triggered via the event bus.
 *
 * Currently:
 * - login/signup
 * - unload alert
 * - payment
 */
import { storeToRefs } from 'pinia';

import { UserSubscription, UserSubscriptionItem } from '@clovyr/pollen';
import { AuthState } from '@clovyr/pollen/pollen';
import type { PaymentMethod } from '@clovyr/pollen/subscription';
import { deepCopy } from '@clovyr/pollen/util/deep-copy';

import PaymentModal from '@/components/blocks/PaymentModal.vue';
import Modal, { type ModalSize } from '@/components/elements/Modal.vue';
import { useAppResume } from '@/composables/useAppResume';
import { useAppStatus } from '@/composables/useAppStatus';
import Toast from '@/global/Toast.vue';
import { type ShowLoginEvent, type ShowPaymentModalEvent, useEventBus } from '@/stores/event_bus';
import { usePollenStore } from '@/stores/pollen_store';
import AuthForm from '@/views/Settings/AuthForm.vue';
import { ShowAuthForm } from '@/views/Settings/types';

const { eventBus } = useEventBus();
const { authState, isUserAuthenticated } = storeToRefs(usePollenStore());
const router = useRouter();

// auth
const showAuthDialog = ref(false);
const loginData = ref<ShowLoginEvent>();
const authTheme: Ref<ModalSize> = ref('narrow');

// unload
const showUnloadAlert = ref(false);

// payments
const showPaymentModal = ref(false);
const paymentID = ref<string>('');
const paymentName = ref<string>('');
const sub = ref<UserSubscription>();
const subItem = ref<UserSubscriptionItem>();

// toast
const showToast = ref(false);
let toastTimeoutId: ReturnType<typeof setTimeout> | null = null;
const toastMessage = ref('');
const toastType: Ref<'success' | 'info' | 'error'> = ref('info');
const toastData = ref({}); // placeholder for future data

const handleAuthModalClose = () => {
  showAuthDialog.value = false;
  if (
    ((loginData.value?.form === ShowAuthForm.Login ||
      loginData.value?.form === ShowAuthForm.Signup) &&
      loginData.value.redirOnCancel) ||
    loginData.value?.form === ShowAuthForm.ResendVerification ||
    loginData.value?.form === ShowAuthForm.VerificationComplete
  ) {
    void router.push('/');
  }
  eventBus.emit('modal:login:close');
};

const handleThemeChange = (theme) => {
  authTheme.value = theme;
};

const handleShowLoginModal = (data: ShowLoginEvent | undefined) => {
  const d = data ? deepCopy(data) : {};
  let formValue = d.form;
  if (!formValue) {
    if (authState.value === AuthState.Locked) {
      formValue = ShowAuthForm.Locked;
    } else if (authState.value === AuthState.LoggedInWithoutSecretKey) {
      formValue = ShowAuthForm.SecretKeyForm;
    } else {
      formValue = ShowAuthForm.Login;
    }
  }

  loginData.value = d;
  showAuthDialog.value = true;
};

const handleShowUnloadModal = () => {
  showUnloadAlert.value = true;
};

const continueToPayment = () => {
  eventBus.off('modal:login:close', continueToPayment);
  if (isUserAuthenticated.value && authState.value === AuthState.LoggedIn) {
    showPaymentModal.value = true;
  }
};

const handleShowPaymentModal = async (e: ShowPaymentModalEvent) => {
  paymentID.value = e.id;
  paymentName.value = e.name;
  sub.value = e.sub;
  subItem.value = e.subItem;

  if (isUserAuthenticated.value && authState.value === AuthState.LoggedIn) {
    // if payment previously captured, do upgrade right away
    if (sub.value.payment_captured_at) {
      const { doUpgrade } = useAppStatus(paymentID);
      if (
        await doUpgrade(
          paymentID.value,
          sub.value,
          subItem.value,
          sub.value.subscription_provider as PaymentMethod,
        )
      ) {
        void useAppResume(paymentID).wakeUpInstance();
      }
      return;
    }
    // show if signed up & logged in
    showPaymentModal.value = true;
  } else {
    // show signup form
    loginData.value = { form: ShowAuthForm.Signup };
    showAuthDialog.value = true;
    eventBus.on('modal:login:close', continueToPayment);
  }
};

const handleShowToast = (duration = 3000) => {
  if (toastTimeoutId) {
    clearTimeout(toastTimeoutId);
  }
  showToast.value = true;
  toastTimeoutId = setTimeout(() => {
    showToast.value = false;
    toastTimeoutId = null;
  }, duration);
};

onMounted(() => {
  eventBus.on('modal:login:show', handleShowLoginModal);
  eventBus.on('modal:login:force_close', () => {
    showAuthDialog.value = false;
  });
  eventBus.on('modal:unload:show', handleShowUnloadModal);
  eventBus.on('modal:payment:show', handleShowPaymentModal);
  eventBus.on('toast:show', ({ message, type, duration, data }) => {
    toastMessage.value = message || '';
    toastType.value = type;
    toastData.value = data;
    handleShowToast(duration || 3000);
  });
});

onUnmounted(() => {
  eventBus.off('modal:login:show', handleShowLoginModal);
  eventBus.off('modal:unload:show', handleShowUnloadModal);
  eventBus.off('modal:payment:show', handleShowPaymentModal);
  eventBus.off('modal:login:close', continueToPayment);
  eventBus.off('toast:show');
});
</script>

<template>
  <!-- Login/signup form -->
  <Modal
    v-if="showAuthDialog"
    theme="transparent"
    :size="authTheme"
    :has-icon="true"
    class="test"
    @close="handleAuthModalClose"
    maxOnMobile
  >
    <template #body>
      <AuthForm
        :active-option="loginData?.form"
        :opts="loginData?.opts"
        @close="handleAuthModalClose"
        @update-theme="handleThemeChange"
      />
    </template>
  </Modal>

  <!-- Unload alert -->
  <Modal
    v-if="showUnloadAlert"
    heading-text="Warning: you currently have one or more applications launching."
    :size="authTheme"
    :has-icon="true"
    @close="showUnloadAlert = false"
  >
    <template #body>
      <p>
        Refreshing or navigating away will interrupt the process and the application may fail to
        launch correctly.
      </p></template
    >
  </Modal>

  <!-- Payment -->
  <PaymentModal
    v-if="showPaymentModal"
    :id="paymentID"
    :name="paymentName"
    :subscription="sub"
    :subscriptionItem="subItem"
    @close="showPaymentModal = false"
  />

  <Teleport v-if="showToast" to="body">
    <Toast :show="showToast" :message="toastMessage" :type="toastType" @hide="showToast = false" />
  </Teleport>
</template>
